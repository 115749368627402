/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable prettier/prettier */

import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box } from '@chakra-ui/react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import SEO from '../components/SEO';
import { AbsoluteHeaderStyles, AdviceGrid } from '../components/AdviceGrid';
import { PageStyles, RightPanelStyles, StandardGridStyles } from '../pages';
import { AdviceCategories } from '../components/AdviceCategories';
import { CreatePostInputLink } from '../components/CreatePostInputLink';
import { GenericHeader } from '../components/GenericHeader';
import { AdviceSortByHeader } from '../components/AdviceSortByHeader';
import { ComediansTrendingList } from '../components/ComediansTrendingList';
import { ComediansSearchBar } from '../components/ComediansSearchBar';

export const PostCategoryHeaderStyles = styled.div`
  position: relative;
  display: none;
  @media (max-width: 840px) {
    display: block;
    margin-bottom: -2rem;
  }
  @media (max-width: 640px) {
    margin-top: 10px;
  }
  @media (max-width: 414px) {
    margin-bottom: 0;
  }
`;

export default function SinglePostCategoryPage({ data }) {
    const advicePerPage = parseInt(process.env.GATSBY_ADVICE_PER_PAGE);
    const [endIndex, setEndIndex] = useState(advicePerPage);
    const callback = () => {
        setEndIndex(endIndex + advicePerPage);
    }
    useBottomScrollListener(callback, {
        offset: 500,
    });
    const category = data ? data.category : null;
    if(!category) return <p>Advice didn't make it 😞</p>
    const {adviceContents} = category;
    const { length } = adviceContents;
  return (
    <>
      <SEO title={`${category.category} | Yuks`} />
      <PageStyles>
        <StandardGridStyles>
            <Box>
                <PostCategoryHeaderStyles>
                    <AbsoluteHeaderStyles>
                        <AdviceSortByHeader noUnderline />
                    </AbsoluteHeaderStyles>
                </PostCategoryHeaderStyles>
                <GenericHeader header={`Posts tagged '${category.category}' (${length})`} />
                <AdviceGrid allAdvice={adviceContents.slice(0, endIndex)} totalLength={length} standalonePage type={category.category} />
            </Box>
            <RightPanelStyles>
                <ComediansSearchBar noLabel />
                <Box mt="10px">
                    <AdviceCategories />
                </Box>
                <Box mt="10px">
                    <CreatePostInputLink />
                </Box>
                <Box mt="10px">
                  <ComediansTrendingList />
                </Box>
            </RightPanelStyles>
        </StandardGridStyles>
      </PageStyles>
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    category: sanityAdviceCategory(slug: { current: { eq: $slug }}) {
        id
        category
        slug {
            current
        }
        adviceContents {
            id
            title
            content
            slug {
                current
            }
            sourceLink
            sourceTitle
            comedian {
                id
                firstName
                lastName
                slug {
                    current
                }
                avatar {
                    asset {
                        fluid {
                        ...GatsbySanityImageFluid
                        }
                    }
                }
            }
            categories {
                id
                category
                slug {
                    current
                }
            }
        }
    }
  }
`;